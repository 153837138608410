import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
} from "@angular/core";
import { ProfileApiI, ProfileI } from "../../pojo/ProfileI";
import { TimeModelI } from "../../pojo/TimeModelI";
import { ProfileUtil } from "../s25-datepattern/profile.util";
import { RepeatType } from "../s25-datepattern/s25.datepattern.picker.component";
import { Bind } from "../../decorators/bind.decorator";
import { TimePickerModel } from "../s25-timepicker/s25.timepicker";
import { S25Util } from "../../util/s25-util";
import { StandardSchedule, StandardScheduleService } from "../../services/standard.schedule.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { UserprefService } from "../../services/userpref.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";

@Component({
    selector: "s25-ng-ql-search-advanced-profile",
    template: ` @if (init) {
        <div class="dropdown-wrapper">
            <s25-ng-dropdown-search-criteria
                [placeholder]="'Select Standard Schedule Patterns'"
                [type]="'standardSchedules'"
                [(chosen)]="chosenSchedule"
                (chosenChange)="addSchedulePattern($event)"
            ></s25-ng-dropdown-search-criteria>
            <span class="success-msg" [class.show-success]="showSuccessMessage()">
                <svg class="c-svgIcon" role="img">
                    <title>Success</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                    ></use>
                </svg>
                {{ chosenSchedule?.itemName }} Pattern(s) Added
            </span>
        </div>
        <table class="table table-bordered ngListTbl ngTable b-listview">
            <thead class="ngTableHeader">
                <tr class="ngTableRow">
                    <th class="b-listview-th ngTableCell">Start Time</th>
                    <th class="b-listview-th ngTableCell">End Time</th>
                    <th class="b-listview-th ngTableCell">Repeat Code</th>
                    <th class="b-listview-th ngTableCell">Copy</th>
                    <th class="b-listview-th ngTableCell">Edit</th>
                    <th class="b-listview-th ngTableCell">Delete</th>
                </tr>
            </thead>
            <tbody>
                @for (p of profiles; track p) {
                    <tr class="b-listview-tr ngListRow ngTableRow">
                        <td class="ngTableCell">
                            {{ p.startTmModel.timeText !== "hh:mm" ? p.startTmModel.timeText : "" }}
                        </td>
                        <td class="ngTableCell">
                            {{ p.endTmModel.timeText !== "hh:mm" ? p.endTmModel.timeText : "" }}
                        </td>
                        <td class="ngTableCell">{{ p.code }}</td>
                        <td class="ngTableCell">
                            <button class="aw-button aw-button--outline" (click)="addPattern(p.queryCode)">Copy</button>
                        </td>
                        <td class="ngTableCell">
                            <button class="aw-button aw-button--outline" (click)="displayEdit(p)">
                                {{ p.edit ? "Done" : "Edit" }}
                            </button>
                        </td>
                        <td class="ngTableCell">
                            <button class="aw-button aw-button--danger--outline" (click)="deleteProfile(p)">
                                Delete
                            </button>
                        </td>
                    </tr>
                    @if (p.edit) {
                        <tr class="edit-row">
                            <td colspan="6">
                                <div class="pattern-form-wrapper">
                                    <div class="search-times">
                                        <label class="c-margin-bottom--half c-margin-right--single search-time">
                                            Start Time
                                            <s25-timepicker
                                                [inputId]="'startTime-' + profiles.indexOf(p)"
                                                [modelBean]="p.startTmModel"
                                                [(modelValue)]="p.startTmModel.dateTime"
                                                (modelValueChange)="timeUpdated('start', $event, p)"
                                            ></s25-timepicker>
                                        </label>
                                        <label class="c-margin-bottom--half c-margin-right--single search-time">
                                            End Time
                                            <s25-timepicker
                                                [inputId]="'endTime-' + profiles.indexOf(p)"
                                                [modelBean]="p.endTmModel"
                                                [(modelValue)]="p.endTmModel.dateTime"
                                                (modelValueChange)="timeUpdated('end', $event, p)"
                                            ></s25-timepicker>
                                        </label>
                                    </div>
                                    <label class="c-margin-bottom--half repeats-by">
                                        <span class="chooseText">Repeats by</span>
                                        <select
                                            class="cn-form__control c-margin-top--quarter c-margin-left--half"
                                            [(ngModel)]="p.repeatType"
                                            (ngModelChange)="changeRepeatType(p)"
                                        >
                                            @for (type of repeatTypes; track type) {
                                                <option [ngValue]="type">
                                                    {{ type.itemName }}
                                                </option>
                                            }
                                        </select>
                                    </label>
                                    @if (!p.resetPattern) {
                                        <s25-ng-date-pattern
                                            [type]="p.model.type"
                                            [profileCode]="p.code"
                                            [profileModel]="p.model"
                                            [patternEndChoices]="[]"
                                            [timeModel]="p.timeModel"
                                            [(apiBean)]="p.api"
                                            (apiBeanChange)="updateQueryProfile(p, $event)"
                                        ></s25-ng-date-pattern>
                                    }
                                </div>
                            </td>
                        </tr>
                    }
                }
            </tbody>
        </table>
        <button class="aw-button aw-button--primary c-margin-right--single" (click)="addPattern()">Add Pattern</button>
        @if (showDoneButton) {
            <button class="aw-button aw-button--secondary" (click)="allDone()">Done</button>
        }
    }`,
    styles: `
        table {
            width: 70%;
        }

        .pattern-form-wrapper {
            display: flex;
            width: 90%;
            padding: 1em;

            .search-times {
                display: flex;
                width: 41%;
                gap: 1.5em;
                align-items: center;

                label {
                    width: 35%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .ngTimepicker {
                        width: 80%;
                    }
                }
            }

            .repeats-by {
                position: relative;
                right: 4.2em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            div[data-type="weekly"],
            div[data-type="daily"],
            div[data-type="monthly"] {
                display: flex;
                gap: 1.5em;
                width: 125%;

                .repeats-every {
                    position: relative;
                    flex-direction: column;
                    justify-content: center;
                    width: 28%;
                    gap: 2px;
                    right: 2em;
                    bottom: 4px;

                    label {
                        margin-bottom: unset !important;
                    }

                    .ngDropdownPaginated {
                        width: 9em;
                    }
                }

                & > div:nth-child(2) {
                    position: relative;
                    right: 2em;
                    bottom: 2px;

                    p {
                        margin-top: unset !important;
                    }
                }
            }
        }
        .edit-row {
            box-shadow:
                inset 0px 11px 8px -10px #ccc,
                inset 0px -11px 8px -10px #ccc;
            border-color: rgba(0, 0, 0, 0.15);
        }

        :host ::ng-deep .ngTableCell {
            text-align: center;
        }

        :host ::ng-deep .ngDropdownPaginated {
            width: 20em;
            margin-bottom: 1em;
        }

        .dropdown-wrapper {
            display: flex;
            gap: 1em;

            .success-msg {
                opacity: 0;
                transition: opacity 0.5s ease-out;
                position: relative;
                top: 3px;
                color: green;

                &.show-success {
                    opacity: 1;
                }
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25QLSearchAdvancedProfileComponent implements OnInit {
    @Input() profileCodes: string[] = []; //["hh:mm|hh:mm|W1 MO TU WE TH FR"];
    @Output() profileCodesChange = new EventEmitter<string[]>();

    init = false;
    profiles: QueryProfileI[] = [];
    repeatTypes: RepeatType[] = [
        //{ itemTypeId: -2, itemName: "Does Not Repeat" },
        { itemTypeId: 1, itemName: "Daily" },
        { itemTypeId: 2, itemName: "Weekly" },
        { itemTypeId: 3, itemName: "Monthly" },
    ];
    showDoneButton: boolean;
    chosenSchedule: S25ItemI | null;
    timeFormat: string;
    showSuccessMessage = signal(false);

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.timeFormat = await UserprefService.getS25Timeformat();
        //create profiles and sort by the code
        this.profiles = this.profileCodes.map(this.modelFromQueryCode).sort((a, b) => a.code.localeCompare(b.code));
        if (this.profiles.length === 1) {
            this.profiles[0].edit = true;
            this.showDoneButton = true;
        }

        this.init = true;
        this.cd.detectChanges();
    }

    @Bind
    modelFromQueryCode(input: string | StandardSchedule): QueryProfileI {
        const isQueryString = typeof input === "string";
        const queryCode = isQueryString ? input : StandardScheduleService.formatPattern(input as StandardSchedule);

        let repeatType = this.repeatTypes[1];
        const parsedCode = this.extractTime(queryCode);

        if (parsedCode.code.startsWith("D")) {
            repeatType = this.repeatTypes[0];
        } else if (parsedCode.code.startsWith("W")) {
            repeatType = this.repeatTypes[1];
        } else if (parsedCode.code.startsWith("MD") || parsedCode.code.startsWith("MP")) {
            repeatType = this.repeatTypes[2];
        }

        const model = {
            type: repeatType.itemName.toLowerCase() as ProfileI["type"],
            repeatEnd: ProfileUtil.getProfileCodeEndingType(queryCode),
        };
        // only needed for the date pattern component - not used in profilecode generation
        const timeModel: TimeModelI = {
            evEndDt: new Date(parsedCode.endTmStr) ?? new Date(),
            evStartDt: new Date(parsedCode.startTmStr) ?? new Date(),
            minutes: {
                setupMinutes: 0,
                preMinutes: isQueryString ? 0 : (input as StandardSchedule).pre_range,
                postMinutes: isQueryString ? 0 : (input as StandardSchedule).post_range,
                takedownMinutes: 0,
            },
        };

        return {
            code: parsedCode.code,
            queryCode: queryCode,
            model: model,
            api: { getProfileCode: () => parsedCode.code }, //will be overwritten by the date pattern component
            timeModel: timeModel,
            repeatType: repeatType,
            edit: false,
            startTmModel: this.timeStrToModel(parsedCode.startTmStr),
            endTmModel: this.timeStrToModel(parsedCode.endTmStr),
        } as QueryProfileI;
    }

    timeStrToModel(timeStr: string): QueryTimeModel {
        const isSet = timeStr !== "hh:mm";
        const parsedDt = isSet && S25Util.date.parseTime(timeStr);
        return {
            initEmpty: !isSet,
            timeText: isSet ? S25Datefilter.transform(parsedDt, this.timeFormat) : "",
            timeStr24: timeStr,
            dateTime: isSet ? parsedDt : undefined,
        };
    }

    @Bind
    updateQueryProfile(profile: QueryProfileI, $event: ProfileApiI) {
        profile.code = $event.getProfileCode();
        profile.queryCode = this.generateQueryCode(profile);
        this.updateCodes();
    }
    @Bind
    updateCodes() {
        this.profileCodes = this.profiles.map((p) => this.generateQueryCode(p));
        this.profileCodesChange.emit(this.profileCodes);
    }

    @Bind
    timeUpdated(type: "start" | "end", time: Date, profile: QueryProfileI) {
        const timeStr = `${S25Util.leftPad(time.getHours(), 2, "0")}:${S25Util.leftPad(time.getMinutes(), 2, "0")}`;
        if (type === "start") {
            profile.startTmModel.timeStr24 = timeStr;
        } else {
            profile.endTmModel.timeStr24 = timeStr;
        }
        profile.queryCode = this.generateQueryCode(profile);
        this.updateCodes();
    }

    generateQueryCode(profile: QueryProfileI) {
        return `${profile.startTmModel.timeStr24}|${profile.endTmModel.timeStr24}|${profile.api.getProfileCode()}`;
    }
    extractTime(queryCode: string = "") {
        const parts = queryCode.split("|");
        return {
            startTmStr: parts[0],
            endTmStr: parts[1],
            code: parts[2],
        };
    }
    changeRepeatType(profile: QueryProfileI) {
        profile.resetPattern = true;
        this.cd.detectChanges();

        profile.model.type = profile.repeatType.itemName.toLowerCase() as ProfileI["type"];
        switch (profile.repeatType.itemTypeId) {
            case 1: //daily
                profile.code = `D1 `;
                break;
            case 2: //weekly
                profile.code = `W1 MO TU WE TH FR `;
                break;
            case 3: //monthly
                profile.code = `MD1 `;
                break;
        }
        profile.queryCode = `${profile.startTmModel.timeStr24}|${profile.endTmModel.timeStr24}|${profile.code}`;
        const newProfile = this.modelFromQueryCode(profile.queryCode);
        profile.model = newProfile.model;
        profile.resetPattern = false;
        this.cd.detectChanges();
        this.updateCodes();
    }

    /** manage table **/
    addPattern(queryCode: string = "hh:mm|hh:mm|W1 MO TU WE TH FR") {
        if (this.profiles.length === 1) this.profiles[0].edit = false;
        let newProfile = this.modelFromQueryCode(queryCode);
        newProfile.edit = true;
        this.profiles.push(newProfile);
        this.showDoneButton = true;
        this.updateCodes();
        this.cd.detectChanges();
    }

    deleteProfile(profile: QueryProfileI) {
        this.profiles = this.profiles.filter((p) => p !== profile);
        if (this.profiles.length === 1) this.profiles[0].edit = true;
        this.updateCodes();
        this.cd.detectChanges();
    }

    displayEdit(profile: QueryProfileI) {
        profile.edit = !profile.edit;
        this.showDoneButton = this.profiles.some((p) => p.edit);
        this.cd.detectChanges();
    }

    allDone() {
        this.profiles.forEach((p) => (p.edit = false));
        this.showDoneButton = false;
        this.cd.detectChanges();
    }

    async addSchedulePattern(scheduleList: S25ItemI) {
        const schedule = await StandardScheduleService.getStandardSchedule([scheduleList.itemId as number]);
        const formattedSchedule = schedule?.map(this.modelFromQueryCode);
        this.profiles = [...this.profiles, ...formattedSchedule];
        this.showSuccessMessage.set(true);

        setTimeout(() => {
            this.showSuccessMessage.set(false);
            this.chosenSchedule = null;
        }, 3000);

        this.updateCodes();
        this.cd.detectChanges();
    }
}

interface QueryProfileI {
    code: string; //profile code in standard format eg. W1 MO TU WE TH FR #3
    queryCode: string; //profile code in query format eg. hh:mm|hh:mm|W1 MO TU WE TH FR -- no wildcards needed here
    model: ProfileI;
    api: ProfileApiI;
    timeModel: TimeModelI; //not used in any meaningful way, needed just for profile component
    edit: boolean;
    startTmModel: QueryTimeModel;
    endTmModel: QueryTimeModel;
    repeatType: RepeatType;
    resetPattern?: boolean; //used to reset the view
}

type QueryTimeModel = TimePickerModel & { timeStr24?: string; dateTime?: Date };
