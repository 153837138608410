import { Component, Input, ViewChild } from "@angular/core";
import { AdvancedModel, S25qlSearchAdvancedCriteriaComponent } from "./s25ql.search.advanced.criteria.component";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Tokenizer } from "./s25ql.tokenizer";
import { S25qlSearchAdvancedInputTaskComponent } from "./s25ql.search.advanced.input.task.component";
import Searches = SearchCriteria.Searches;
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-ql-search-advanced-input")
@Component({
    selector: "s25-ng-ql-search-advanced-input",
    template: `
        @if (type !== 10) {
            <s25-ng-ql-search-advanced-criteria
                [type]="type"
                [model]="model"
                [searches]="searches"
            ></s25-ng-ql-search-advanced-criteria>
        }
        @if (type === 10) {
            <s25-ng-ql-search-advanced-task [model]="model"></s25-ng-ql-search-advanced-task>
        }
    `,
    styles: [``],
})
export class S25QLSearchAdvancedInputComponent {
    @Input() type: Tokenizer.QLItemTypes;
    @Input() model: AdvancedModel;
    @Input() searches: Searches;

    @ViewChild(S25qlSearchAdvancedCriteriaComponent) criteriaComponent: S25qlSearchAdvancedCriteriaComponent;
    @ViewChild(S25qlSearchAdvancedInputTaskComponent) taskCriteriaComponent: S25qlSearchAdvancedInputTaskComponent;

    validate() {
        if (this.criteriaComponent) return this.criteriaComponent.validate();
        if (this.taskCriteriaComponent) return this.taskCriteriaComponent.validate();
    }
}
